function onSignIn(googleUser) {
  let idToken = googleUser.getAuthResponse().id_token;
  sendIdToken(idToken)
    .then((data) => {
      // redirect after succesful login
      if (data.redirectUrl) {
        location.href = data.redirectUrl;
        // hide login button
        let loginButton = document.getElementById("login-button");
        loginButton.classList.add("d-none");
      } else if (data.message) {
        // Login failed
        showErrorMessage(data.message);
      }
      // Sign out of google, ready for next attempt or if server session expired
      signOutGoogle();
    })
    .catch((error) => {
      console.log("Connection failed: ", error.message);
    });
}

async function sendIdToken(idToken) {
  //  Send google token to verify on backend
  let loginUrl = "/login";
  let response = await fetch(loginUrl, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ idToken }),
  });
  return response.json();
}

function signOutGoogle() {
  // Sign out from google on client
  var auth2 = gapi.auth2.getAuthInstance();
  auth2.signOut().then(function () {
    console.log("Google account signed out.");
  });
}

function showErrorMessage(message) {
  //  Show a message with error to user

  let messagesDiv = document.getElementById("messages");

  let htmlMessage = `<div class="alert alert-warning alert-dismissible fade show py-3" role="alert">
  <strong>${message}</strong>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>`;

  messagesDiv.innerHTML = htmlMessage;
}
